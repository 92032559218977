/* ==============
  General
===================*/

body {
  margin: 0;
  overflow-x: hidden;
}
html {
  overflow-x: hidden;
  position: relative;
  min-height: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0;
}
h1 {
  line-height: 43px;
}

h2 {
  line-height: 35px;
}

h3 {
  line-height: 30px;
}

h4 {
  line-height: 22px;
}

b,
strong {
  font-weight: 500;
}

* {
  outline: none !important;
}

a {
  &:hover {
    outline: 0;
    text-decoration: none;
  }
  &:active {
    outline: 0;
    text-decoration: none;
  }
  &:focus {
    outline: 0;
    text-decoration: none;
  }
}

.container {
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.container-alt {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1170px;
}

.footer {
  background-color: $white;
  border-top: 1px solid rgba($dark, 0.05);
  bottom: 0;
  padding: 20px 30px;
  position: absolute;
  right: 0;
  left: 240px;
  font-size: 14px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

#wrapper {
  height: $height;
  overflow: hidden;
  width: $width;
}

.page {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.font-80 {
  font-size: 1.5vh !important;
}

.font-90 {
  font-size: 0.875rem !important;
}

.table-striped {
  tr {
    th {
      font-weight: bold;
      padding: 10px 3px;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }

      &:nth-child(1) {
        text-align: center;
      }
    }

    td {
      padding: 10px 3px !important;
      word-wrap: break-word;
      vertical-align: middle;
      font-size: 1.5vh;
      text-align: left;
      overflow-x: auto;

      &:nth-child(7),
      &:nth-child(8) {
        min-width: 3.8vw;
      }

      &:nth-child(9),
      &:nth-child(10),
      &:nth-child(11) {
        min-width: 8vw;
      }

      &:nth-child(1) {
        min-width: 1vw;
        text-align: center;
      }

      &:nth-child(2) {
        min-width: 15vw;
      }

      &:nth-child(3),
      &:nth-child(4) {
        min-width: 6vw;
      }
    }
  }
}

.table-data-striped {
  tr {
    th {
      text-align: left !important;

      &:hover {
        cursor: text;
        opacity: 1;
      }
    }

    td {
      font-size: 0.7em;
      &:nth-child(1) {
        text-align: left !important;
      }
    }
  }
}

.table-compact {
  max-height: 300px;
  overflow-y: auto;
}

.cursor-pointer {
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

/* Social */
.social-links {
  li {
    a {
      -webkit-border-radius: 50%;
      background: lighten($light, 4%);
      border-radius: 50%;
      color: lighten($lightdark7, 15%);
      display: inline-block;
      height: 30px;
      line-height: 30px;
      text-align: center;
      width: 30px;
    }
  }
}

@media print {
  .font-80 {
    font-size: 0.7em !important;
  }

  .table-striped {
    tr {
      td {
        font-size: 0.7em;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .table-striped {
    th {
      font-size: 0.9em !important;

      &:nth-child(1n) {
        display: none;
      }

      &:nth-child(1),
      &:nth-child(2) {
        display: table-cell;
      }
    }

    td {
      font-size: 0.85em !important;

      &:nth-child(1n) {
        display: none;
      }

      &:nth-child(1),
      &:nth-child(2) {
        display: table-cell;
      }
    }

    tfoot {
      display: none;
    }
  }
}

.rounded-logo {
  border-radius: 10% !important;
}

.form-group {
  margin-bottom: 0.7rem !important;
}

.gridPend {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 50px;
}

.button-inline {
  border: "0.5px solid #aaa" !important;
  border-radius: 5% !important;
}
