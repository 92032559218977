.checklist {
    list-style: none; /* remover marcadores de lista */
    padding: 0; /* remover padding */
  }
  
  .checklist li {
    display: flex; /* para alinhar o checkbox e o texto */
    margin-bottom: 10px; /* espaçamento entre os itens */
  }
  
  .checklist li input[type="checkbox"] {
    margin-right: 10px; /* espaçamento entre o checkbox e o texto */
  }
  
  /* estilo para quando o checkbox está marcado */
  .checklist li input[type="checkbox"]:checked + label {
    text-decoration: line-through; /* riscar o texto */
    color: gray; /* mudar a cor do texto */
  }
  
  .zebra div:nth-child(2n+1) {
    background-color: #eee;    
}

.btn-small {
  background-color: rgb(23, 162, 184);
  color: #fff;
  padding: 3px 6px;  
  border: none;
  border-radius: 5px;
  font-size: 12px;  
  letter-spacing: 0.5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-small:hover {
  background-color: #128B9E;
}

.btn-small-success {
  background-color: #28a745;
  color: #fff;
  padding: 3px 6px;  
  border: none;
  border-radius: 5px;
  font-size: 12px;  
  letter-spacing: 0.5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-small-success:hover {
  background-color: #078E26;
}

.title-json{
  font-size: 16px;
  font-weight: bold;
  color: darkgray;
  align-items: center;
  text-align: center;  
  /* text-decoration: underline; */
}