.button0 {
  width: 1.8em;
  border: 1.8px solid rgb(114, 116, 118);
  vertical-align: middle;
  border-radius: 2px;
  background-color: #CEE1CD;
  // "theme_color":"#7fc8e9","background_color": "#3E6C84";
}

.button01 {
  width: 1.8em;
  border: 1.8px solid rgb(114, 116, 118);
  vertical-align: middle;
  border-radius: 2px;
  // background-color: #3e6c84;
  // "theme_color":"#7fc8e9","background_color": "#3E6C84";
}

.input0 {
  // marginLeft: "0.1em";
  // marginRight: "0.1em";
  width: 120px;
  border-radius: 4px;
  vertical-align: middle;
  border: 1.5px solid #727476;
  text-align: right;
  padding-right: 5px;
}

.button1 {
  background-image: linear-gradient(#f7f8fa, #e7e9ec);
  border-color: #adb1b8 #a2a6ac #8d9096;
  border-style: solid;
  border-width: 0.5px;
  box-shadow: rgba(255, 255, 255, 0.6) 0 0, 5px 0 inset;
  box-sizing: border-box;
  color: #0f1111;
  cursor: pointer;
  display: inline-block;
  font-family: "Amazon Ember", Arial, sans-serif;
  font-size: 14px;
  // height: 29px;
  outline: 0;
  overflow: hidden;
  padding: 0 11px;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  width: 3em;
  vertical-align: middle;
  border-radius: 3px;

  .button1-b {
    border-color: "1px solid rgb(114, 116, 118)";
  }
}

.button1:active {
  border-bottom-color: #a2a6ac;
}

.button1:active:hover {
  border-bottom-color: #a2a6ac;
}

.button1:hover {
  border-color: #a2a6ac #979aa1 #82858a;
}

.button1:focus {
  border-color: #e77600;
  box-shadow: rgba(228, 121, 17, 0.5) 0 0 3px 2px;
  outline: 0;
}

.button2 {
  background-color: #ffffff;
  border: 0;
  border-radius: 0.5rem;
  box-sizing: border-box;
  color: #111827;
  font-family: "Inter var", ui-sans-serif, system-ui, -apple-system, system-ui,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 0.75rem 1rem;
  text-align: center;
  text-decoration: none #d1d5db solid;
  text-decoration-thickness: auto;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button2:hover {
  background-color: rgb(249, 250, 251);
}

.button2:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.button2:focus-visible {
  box-shadow: none;
}
