$white: #ffffff;

$primary: #17a2b8;
$secondary: #9ca1a5;
$success: #28d3ab;
$info: #00bdff;
$warning: #ffbb44;
$danger: #e84848;
$dark: #3c4959;
$muted: #707070;
$pink: #f06292;
$purple: #8938d5;
$brown: #8d6e63;

$blue-grey: #90a4ae;
$lightdark5: #555555;
$lightgray: #dddddd;
$light: #eeeeee;
$lightdark3: #333333;
$lightdark4: #444444;
$light5: #f5f5f5;
$light3: #f3f3f3;
$light9: #f9f9f9;
$light-alt: #fafafa;
$lightdark7: #797979;
$lightdark-alt: #cccccc;

$bg-body: #f2f5f7;
$top-bar: #17a2b8;

$box-shadow: 3px 8px 5px rgba(29, 30, 58, 0.03);

// Width variables
$width: 100%;

// Height variables
$height: 100%;
