.json {
    background: #f2f2f2;
    height: 100%;
    width: 100%;
}

.line {
    margin-left: 1rem;
}

.key {
    margin-right: 0.5rem;
    color: black;
    font-weight: 600;
}

.string {
    color: green;
}

.number {
    color: blue;
}

.boolean {
    color: purple;
}

.null {
    color: red;
}