.Card {
  /* background-color: lightslategray; */
  border: 1.5px solid;
  border-radius: 5px;
  overflow: hidden;
  margin: 2px;
  display: flex;
  flex-direction: column;
  max-width: 150px;
  min-width: 150px;
  min-height: 150px;
  max-height: 150px;
  margin-bottom: 10px;
}

.Card .Title {
  /* padding: 5px 0px; */
  display: flex;
  flex-direction: line;
  justify-content: space-between;
  /* border-radius: 1px; */
  padding: 0px;
  border-bottom: 0.5px solid;
  /* align-items: center; */
}

.Card .Content {
  flex-grow: 1;
  background-color: white;
  color: black;
  padding: 0;
}