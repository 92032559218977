/* .body{
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
} */

.dashboardContainer {
    background-color: #1d313c;
    padding: 0rem 0rem 3.125rem;
}

.statisticContainer {
    display: flex;
    flex-wrap: wrap;
    /* width: 200px; */
    justify-content: center;
    gap: 1.0rem;
    margin-top: 10px;
    border-radius: 20px;
    background: #3e6c84;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: 465px;
    padding: 1.125rem 0.75rem;
}

.statisticContent {
    position: relative;
    display: flex;
    flex-direction: column;
    /* gap: 0.875rem; */
    gap: 1.0rem;
    width: 100%;
    text-align: center;
}

.title {
    background-color: white;
    padding: 0.25rem 0.125rem;

    color: #1e1e1e;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 5px;
}

.value {
    color: #fff;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.table {
    max-height: 5rem;
    margin: auto;
    flex-shrink: 0;
    margin-top: 10px;
    border: none;
}

.tableHead tr {
    border: none;
}

.tableHead tr th {
    border-radius: 27px 27px 0px 0px;
    background: #d9d9d9;
    padding: 0px;
    border: none;
}

.tableBody tr td {
    background: #3e6c84;
    text-align: center;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    border-bottom: none;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.tableBody tr td:last-of-type,
.tableBody tr td:first-of-type {
    border-right: none;
    border-left: none;
    border-bottom: none;
}

.tableBody tr:first-child td {
    color: #d9d9d9;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.tableBody tr:nth-child(2) td {
    color: #fff;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.tableBody tr:last-child td:first-child {
    border-radius: 0px 0px 0px 27px !important;
}

.tableBody tr:last-child td:last-child {
    border-radius: 0px 0px 27px 0px !important;
}

.dailyProductionContainer {
    position: relative;
    height: 220px;
    border-radius: 20px;
    background-color: #17a2b8;
    padding: 10px 0px 0px;
    font-weight: bold;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.graphLegend {
    position: absolute;
    top: 0.2375rem;
    left: 20%;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;

    color: #fff;

    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.legendText:first-of-type span {
    position: absolute;
    top: 3px;
    left: -15px;
    width: 10px;
    height: 10px;
    background-color: #d9d9d9;
    border-radius: 50%;
}

.legendText:last-of-type span {
    position: absolute;
    top: 30px;
    left: -15px;
    width: 10px;
    height: 10px;
    background-color: #3E6C84;
    border-radius: 50%;
}

.graphTitle {
    position: relative;
}

.subtitle {
    position: absolute;
    top: 0.9rem;
    right: 4.0625rem;

    color: #d9d9d9;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.shadowbox {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.productionInfoContainer {
    display: flex;
    justify-content: space-between;

    gap: 1.25rem;
    justify-content: space-between;
    margin: 25px 0px 1.25rem 0px;
    
}

.productionInfoContent {
    width: 11rem;
    height: 5rem;

    /* margin-right: 10px; */
 
    text-align: center;
    display: flex;
    flex-direction: column;
}

.titleProduction {
    padding: 1rem 0.6875rem 0.6875rem;

    background-color: #d9d9d9;
    color: #1d313c;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    border-radius: 20px 20px 0 0;
}

.smallerFontSize {
    font-size: 0.625rem !important;
    /* color: red; */
}

.valueProduction {
    background-color: #3e6c84;
    padding: 0.875rem 0rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    border-radius: 0 0 20px 20px;
}

.selectInputsArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5px;
    margin: 0.625rem 0px;
    width: 100%;
}



.selectContainer {
    width: 95%;
    height: 60px;

    display: flex;
    align-items: center;
    justify-content: center;
    max-width:100%;
    border-radius: 20px;
    background: #3e6c84;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 0px 10px;
}

.boxPosition {
    margin-top : -135px;
}

.select {
    width: 100%;
    height: 35.294px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: #1d313c;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
input[type=date]{
    width: 100%;
    height: 35.294px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: #1d313c;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.productionHistoryContainer {
    /* width: 21.25rem; */
    height: 13.75rem;

    background-color: #17a2b8;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 0px 10px;
}

@media (min-width: 400px) {

    .statisticContainer {
        gap: 1.25rem;
        flex-wrap: nowrap;
        width: 100%;
        flex-direction: column;
        margin-top: 0px;
    }

    .shadowbox {
        margin-top:8px
    }

    /* .productionInfoContent:nth-child(n+2) {
        margin: 10px !important; 
    } */

    .selectInputsArea {
        margin-bottom: 20px;
   }

}

@media (min-width: 500px) {
    
    .statisticContainer {
        gap: 1.25rem;
    }

    .selectInputsArea {
        gap: 1.25rem;
    }
}

@media (max-width: 967px) {
    .boxPosition {
        margin-top : 0px;
    }
    
    .productionInfoContainer {
        flex-wrap: wrap;
        padding:0px 10px;
        

    }
    .productionInfoContent {
        width: 100%
    }

    .productionInfoContainer {
        margin-top: 10px !important; 
    }

    /* .productionInfoContent:nth-child(n+2) {
        margin: 5px !important; 
    } */
    
    
}


@media (min-width: 985px) {
    .statisticContainer {
        display: flex;
        flex-direction: column;
        margin-top: 30px !important;
        margin: 0rem;
        gap: 1.25rem;
    }
    
}

@media (min-width: 975px) {
    .selectInputsArea {
        margin: 0rem;
        gap: 1.25rem;
        flex-direction: row;
    }

    
}


@media (max-width: 985px) {
    .productionHistoryContainer {
        margin-bottom: 1.25rem;
        margin: 0px 10px 20px;
    }

    .statisticContainer {
        margin-bottom: 1.25rem;
        margin: 0px 10px 20px;
        
    }

    .table {
        margin-top:10px
    }

    .dailyProductionContainer {
        margin-bottom: 1.25rem;
        margin: 0px 10px 20px;
    }

    .graphLegend {
        position: absolute;
        top: 1.875rem;
        left: 50%;
        display: flex;
    }
    .barLabel {
        display: none;
    }

    
}


