/* =============
   Tabs & Accordions
============= */


.nav-tabs-custom {
  border-bottom: 2px solid #DDD;

  .nav-item {
    position: relative;

    .nav-link {
      border: none !important;
    }
  }
}

.nav-tabs-custom > li > a {
  color: $dark;
}

.nav-tabs-custom > li > a::after {
  content: "";
  background: $primary;
  height: 2px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -1px;
  transition: all 250ms ease 0s;
  transform: scale(0);
}

.nav-tabs-custom > li > a.active::after, .nav-tabs-custom > li:hover > a::after {
  transform: scale(1);
}

.nav-tabs-custom > li > a.active {
  color: $primary !important;
}