/* ==============
  Tables
===================*/

.table {
  margin-bottom: 10px;
}

th {
  font-weight: 500;
  font-size: 0.9rem;
}

.table>tbody>tr>td, .table>tfoot>tr>td, .table>thead>tr>td {
  padding: 15px 12px;
}

.table-sm td, .table-sm th {
  padding: 8px 6px;
}

.table-hover tbody tr:hover,.table-striped tbody tr:nth-of-type(odd),
.thead-default th{
  background-color: rgba(0, 0, 0, 0.03);
}

.dt-button-collection.dropdown-menu {
  visibility: inherit;
  opacity: 1;
  display: none;
  margin: 0;
}

/* == Responsive Table ==*/
table.focus-on {
  tbody {
    tr.focused {
      th {
        background-color: $primary;
        color: $white;
      }
      td {
        background-color: $primary;
        color: $white;
      }
    }
  }
}

.table-rep-plugin {
  .btn-toolbar {
    display: block;
  }

  .btn-default {
    background-color: $secondary;
    border-color: $secondary;
    color: $white;
    &:focus{
      box-shadow: none;
    }
  }
  .btn-default.btn-primary {
    background-color: $primary;
    border-color: $primary;
  }
  .table-responsive {
    border: none;
  }
  .btn-group.pull-right .dropdown-menu {
    transform: none !important;
    top: 100% !important;
    left: auto !important;
    right: 0;
  }

  tbody {
    th {
      vertical-align: middle;
    }
  }
  .checkbox-row {
    padding-left: 40px;

    label {
      display: inline-block;
      padding-left: 5px;
      position: relative;
      &::before {
        -o-transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        background-color: $white;
        border-radius: 3px;
        border: 1px solid $lightdark-alt;
        content: "";
        display: inline-block;
        height: 17px;
        left: 0;
        margin-left: -20px;
        position: absolute;
        transition: 0.3s ease-in-out;
        width: 17px;
        outline: none;
      }
      &::after {
        color: $lightdark5;
        display: inline-block;
        font-size: 11px;
        height: 16px;
        left: 0;
        margin-left: -20px;
        padding-left: 3px;
        padding-top: 1px;
        position: absolute;
        top: -1px;
        width: 16px;
      }
    }
    input[type="checkbox"] {
      cursor: pointer;
      opacity: 0;
      z-index: 1;
      outline: none;

      &:disabled + label {
        opacity: 0.65;
      }
    }
    input[type="checkbox"]:focus + label {
      &::before {
        outline-offset: -2px;
        outline: none;
      }
    }
    input[type="checkbox"]:checked + label {
      &::after {
        content: "\f00c";
        font-family: 'FontAwesome';
      }
    }
    input[type="checkbox"]:disabled + label {
      &::before {
        background-color: $light;
        cursor: not-allowed;
      }
    }
    input[type="checkbox"]:checked + label {
      &::before {
        background-color: $primary;
        border-color: $primary;
      }
      &::after {
        color: $white;
      }
    }
  }
  .dropdown-menu li.checkbox-row label {
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .fixed-solution  {
    .sticky-table-header{
      top: 70px !important;
    }
  }
}

.table-vertical {
  td {
    vertical-align: middle;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}