/* ==============
  Menu
===================*/

.topbar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;

  .topbar-left {
    float: left;
    position: relative;
    width: 240px;
    z-index: 1;
    text-align: center;
    background-color: lighten($top-bar, 1%);
  }
}

.logo {
  color: $white !important;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.08em;
  line-height: 70px;
  text-transform: uppercase;
}

.navbar-custom {
  background-color: $top-bar;
  border: none;
  box-shadow: 0 2px 3px -2px rgba(0, 0, 0, 0.15);

  .dropdown.show {
    .nav-link {
      background-color: rgba($white, 0.2);
    }
  }
  .language-switch a img {
    float: right;
    margin-top: 5px;
  }
}

.formsign-header-custom {
  // fontSize: 30, padding: 0, justifyContent: "center", color: "white", backgroundColor: $top-bar
  // height: 30px;
  font-size: 30px;
  padding: 0px;
  justify-content: center;
  // color: rgba(0, 0, 0, 0.8);
  color: white;
  background-color: $top-bar;
}

.formsign-footer-custom {
  // style={{ padding: "0px", justifyContent: "center", textAlign: "center", display: "flex", color: "white", backgroundColor: "#252525" }}
  font-size: 30px;
  padding: 0px;
  justify-content: center;
  text-align: center;
  display: flex;
  color: white;
  background-color: rgba(0, 0, 0, 0.65);
}

/* Notification */
.notification-list {
  margin-left: 0;

  .dropdown-menu.dropdown-menu-right {
    transform: none !important;
    top: 100% !important;
    right: 0 !important;
    left: auto !important;
  }

  .noti-title {
    border-radius: 0.25rem 0.25rem 0 0;
    margin: -4px 0px 0px 0px;
    width: auto;
    padding: 12px 20px;

    h5 {
      margin: 0;
      font-size: 14px;
      text-transform: uppercase;
    }

    .label {
      float: right;
    }
  }

  .noti-icon {
    font-size: 28px;
    vertical-align: middle;
    color: $white;
  }
  .noti-icon-badge {
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 12px;
    border-radius: 50%;
    width: 10px;
    height: 10px;
  }
  .notify-item {
    padding: 15px 20px;

    .notify-icon {
      float: left;
      height: 36px;
      width: 36px;
      line-height: 36px;
      text-align: center;
      margin-right: 10px;
      border-radius: 50%;
      color: $white;

      img {
        margin-top: 4px;
      }
    }
    .notify-details {
      margin-bottom: 0;
      overflow: hidden;
      margin-left: 45px;
      text-overflow: ellipsis;
      white-space: nowrap;
      b {
        font-weight: 500;
      }
      small {
        display: block;
        white-space: normal;
      }
      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
      }
    }
  }
  .notify-all {
    border-radius: 0 0 0.25rem 0.25rem;
    margin: 0 0 -5px 0;
    background-color: $light;
  }
  .profile-dropdown {
    .notify-item {
      padding: 4px 20px;
    }
  }
  .nav-link {
    padding: 0 12px;
    line-height: 70px;
  }
  .nav-link.text-muted {
    color: $white !important;
  }
}

.profile-dropdown {
  width: 170px;
  i {
    font-size: 16px;
    vertical-align: middle;
    margin-right: 5px;
  }

  @media only screen and (max-width: 800px) {
    width: 100px;

    i {
      font-size: 12px;
      vertical-align: middle;
      margin-right: 3px;
    }
  }
}

.nav-user {
  margin-right: 10px;
  img {
    height: 36px;
    width: 36px;
  }
}

.arrow-none:after {
  border: none;
  margin: 0;
  display: none;
}

.menu-title {
  padding: 12px 25px !important;
  letter-spacing: 0.035em;
  pointer-events: none;
  cursor: default;
  font-size: 13px;
  color: rgba($dark, 0.5);
  margin-top: 10px !important;
}

.profile {
  padding: 17px 15px !important;

  img {
    border: 2px solid #edf0f0;
    height: 36px;
    width: 36px;
    float: left;
  }

  .profile-username {
    margin-left: 45px;
    display: block;
    line-height: 36px;
  }
}

.navbar-nav {
  margin: 0;
}

.side-menu {
  bottom: 0;
  top: 0;
  width: 240px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background: $white;
  position: absolute;
  z-index: 99;
  box-shadow: $box-shadow;
}

body.fixed-left {
  .side-menu.left {
    bottom: 0;
    margin-top: 0;
    position: fixed;
    top: 70px;
  }
}

.content-page {
  margin-left: 240px;
  overflow: hidden;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content-page > .content {
  margin-bottom: 60px;
  padding: 20px 20px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.page-content-wrapper {
  margin: 0 -10px;
  padding-top: 50px;
}

.button-menu-mobile {
  background-color: $top-bar;
  font-size: 28px;
  width: 42px;
  border-radius: 3px;
  border: none;
  color: $white;
  line-height: 68px;
  margin-left: 20px;
}

.sidebar-inner {
  height: $height;
}

.search-wrap {
  background-color: $white;
  color: $dark;
  z-index: 9997;
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  height: 70px;
  padding: 0 15px;
  transform: translate3d(0, -100%, 0);
  transition: 0.3s;

  form {
    display: flex;
    width: 100%;
  }
  .search-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .search-input {
    flex: 1 1;
    border: none;
    outline: none;
    box-shadow: none;
    background-color: transparent;
  }
  .close-search {
    width: 36px;
    height: 64px;
    line-height: 64px;
    text-align: center;
    color: inherit;
    font-size: 24px;

    &:hover {
      color: $danger;
    }
  }
}

.search-wrap.open {
  transform: translate3d(0, 0, 0);
}

#sidebar-menu,
#sidebar-menu ul,
#sidebar-menu li,
#sidebar-menu a {
  border: 0;
  font-weight: normal;
  line-height: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  text-decoration: none;
}

.navbar-nav > li > a.notification-icon-box {
  line-height: 70px;

  i {
    font-size: 26px;
    vertical-align: middle;
  }
}

#sidebar-menu {
  background-color: $white;
  padding-bottom: 30px;
  width: 100%;

  ul {
    ul {
      display: none;
      li {
        border-top: 0;
      }
      li.active {
        a {
          color: $primary;
        }
      }
      a {
        color: lighten($dark, 12%);
        display: block;
        padding: 12px 20px 12px 55px;
        font-size: 14px;

        &:hover {
          color: $dark;
        }
        i {
          margin-right: 5px;
        }
      }
      ul {
        a {
          padding-left: 75px;
        }
      }
    }
  }
}

#sidebar-menu > ul > li > a {
  color: lighten($dark, 10%);
  display: block;
  padding: 15px 20px;
  background-color: $white;

  &:hover {
    color: $dark;
    text-decoration: none;
  }

  span {
    i {
      font-size: 18px;
      line-height: 18px;
    }
  }
}

.has_sub.nav-active i.mdi-chevron-right:before {
  content: "\F140";
}

#sidebar-menu > ul > li.nav-active > a {
  background-color: darken($white, 4%);
  color: $dark;
}
#sidebar-menu > ul > li > a > span {
  vertical-align: middle;
}

#sidebar-menu > ul > li > a > i {
  display: inline-block;
  font-size: 18px;
  line-height: 17px;
  margin-left: 3px;
  margin-right: 10px;
  text-align: center;
  vertical-align: middle;
  width: 20px;
}

#sidebar-menu > ul > li > a > i.i-right {
  float: right;
  margin: 3px 0 0 0;
}

#sidebar-menu > ul > li > a.active {
  color: $primary;

  i {
    color: $primary;
  }
}

#sidebar-menu > ul > li.nav-active > ul {
  display: block;
}

#wrapper.enlarged {
  .left.side-menu {
    padding-top: 0;
    z-index: 5;
    margin-left: -100%;
  }
  .content-page {
    margin-left: 0;
  }
}

#wrapper.enlarged .footer {
  left: 0;
}

.user-details {
  min-height: 80px;
  padding: 20px;
  position: relative;
  color: rgba($dark, 0.8);

  img {
    position: relative;
    z-index: 9999;
    height: 64px;
    width: 64px;
  }
  .user-info {
    text-align: center;

    .user-status {
      display: inline-block;
      padding: 3px 10px;
      border: 1px solid rgba($white, 0.8);
      border-radius: 20px;
      font-size: 12px;
    }
  }
}

.page-title-box {
  padding: 15px 25px;
  margin: 0 -25px 20px -25px;
  background-color: $white;
  border-bottom: 1px solid rgba($muted, 0.12);

  .page-title {
    font-size: 20px;
    margin-bottom: 0;
    margin-top: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .breadcrumb {
    margin-top: 5px !important;
    .breadcrumb-item {
      a {
        color: $dark;
      }
    }
  }
}
