/* ==============
  Widgets
===================*/
.widget-chart li {
  width: 31.5%;
  display: inline-block;
  padding: 0;

  i {
    font-size: 28px;
  }

}

.mini-stat {
  border: 1px solid rgba($muted,0.12);
  padding: 20px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-bottom: 20px;
}

.mini-stat-icon {
  width: 60px;
  height: 60px;
  display: inline-block;
  line-height: 60px;
  text-align: center;
  font-size: 30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
  color: $white;
}

.mini-stat-info {
  font-size: 14px;
  padding-top: 2px;
}

.mini-stat-info span {
  display: block;
  font-size: 22px;
  font-weight: 400;
}


/* Inbox-widget */

.inbox-widget {
  .inbox-item {
    border-bottom: 1px solid #f1f1f1;
    overflow: hidden;
    padding: 10px 0;
    position: relative;
    .inbox-item-img {
      display: block;
      float: left;
      margin-right: 15px;
      width: 40px;
    }
    img {
      width: 40px;
    }
    .inbox-item-author {
      color: #333333;
      display: block;
      margin: 0;
    }
    .inbox-item-text {
      color: #a0a0a0;
      display: block;
      font-size: 12px;
      margin: 0;
    }
    .inbox-item-date {
      color: #a9a9a9;
      font-size: 11px;
      position: absolute;
      right: 7px;
      top: 2px;
    }
  }
}




// Widget Chart
.widget-chart-sm {
  svg {
    float: left;
  }
}


