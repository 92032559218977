@media only screen and (max-width: 375px) {
  .font-mobile {
    font-size: 14px !important;
  }
  .lead {
    font-size: 14px !important;
  }
  .custom-td {
    width: 40% !important;
  }
  .footer {
    text-align: center !important;
  }
}

.tr-custom:nth-child(2n + 2) {
  background: #f1f1f1;
}

.swal-error {
  width: 800px;
}

.swal-confirm {
  width: 600px;
}

.swal-warning {
  width: 600px;
}

/* .bg-secondary {
  background-color: #9D9D9D !important;
}  */

/* .font-weight-bold {
  font-weight: bold !important;
} */

.mark {
  text-decoration: line-through !important;
}

.bg-danger {
  background-color: #e2504c !important;
}

.disabled {
  cursor: not-allowed;
  background-color: #ebebe4 !important;
  color: #858484;
}

.container, .content {
  background-color: #f6f7f9 !important;
}

.btn-c-gray{
  /* background: linear-gradient(85deg, #ebebe4, #9D9D9D); */
}