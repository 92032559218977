/* ==============
  Account Pages
===================*/

$primary: #66b2cc;
$white: #ffffff;

.simulatorbg {
  // background: url("../images/simulator-bg.jpg");
  background: url("../images/simulator-bg-test.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.8;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.accountbg {
  background: url("../images/wrapper-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.4;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.fundoInss {
  background: url("../images/leilaoinss.png") center/contain no-repeat;
  // filter: brightness(0.5); /* Escurece a imagem de fundo */
  opacity: 0.6;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.fundoInssMarcaDagua {
  background: url("../images/leilaoinss.png") center/contain no-repeat;
  // filter: brightness(0.5); /* Escurece a imagem de fundo */
  opacity: 0.2;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.wrapper-page {
  .logo-admin {
    font-size: 28px;
    line-height: 70px;
  }

  .logo-admin img {
    max-height: 3em;
    max-width: 6em;
  }

  > .card {
    border-radius: 0.5rem;
    border: 5px double lighten($primary, 30%);
    box-shadow: 0 0 53px rgba(0, 0, 0, 0.3);
  }
}

.user-thumb {
  position: relative;
  z-index: 999;
  img {
    height: 88px;
    margin: 0 auto;
    width: 88px;
  }
}

.ex-page-content {
  h1 {
    font-size: 98px;
    font-weight: 700;
    line-height: 150px;
    text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px,
      rgba(61, 61, 61, 0.3) 3px 3px;
  }
}

.account-page-full {
  left: 0;
  position: absolute;
  height: 100%;
  margin: 0;
  width: 420px;
  background-color: $white;

  .card {
    border: none;
  }
}

/* Media query para telas de celular */
// @media (max-width: 768px) {
//   .fundoInss {
//     background-size: cover;
//   }
// }

/* Media query para telas de celular */
@media (max-width: 768px) {
  .fundoInss {
    background-position: center bottom; /* Alinha a imagem ao fundo da tela */
  }
}
